const bigWin = (
    state = {
        bigWinAlert: false,
        period: 0,
        bigWinAmount: 0,
        orderMaskID: [],
    },
    action,
) => {
    switch (action.type) {
        case 'TOGGLE_BIG_WIN':
            state = {
                ...state,
                bigWinAlert: !state.bigWinAlert,
            };
            break;
        case 'OPEN_BIG_WIN':
            let orderMaskID = [];
            action.payload.lsOrders.map((id, index) => {
                orderMaskID.push(id.order_id_mask);
            });
            state = {
                ...state,
                bigWinAlert: true,
                period: action.payload.period_code,
                bigWinAmount: action.payload.amount,
                orderMaskID,
            };
    }
    return state;
};

export default bigWin;
