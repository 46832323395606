const rulesReducer = (
    state = {
        checkAsync: false,
        value: '',
    },
    action,
) => {
    switch (action.type) {
        case 'CHANGE_ASYNC':
            state = {
                ...state,
                checkAsync: !state.checkAsync,
                value: action.payload,
            };
            break;
    }
    return state;
};

export default rulesReducer;
