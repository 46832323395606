import React from 'react';
import ReactLoading from 'react-loading';
import './Loading.scss';

export class Loading extends React.Component {
    render() {
        return (
            <div>
                <ReactLoading className={'loader'} type={'spin'} color={'#00aced'} />
                <div className={'XN-loading'} />
            </div>
        );
    }
}
