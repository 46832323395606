const notificationReducer = (
    state = {
        notifType: 'success',
        message: '',
        trigger: true,
        time: null,
        position: null,
    },
    action,
) => {
    switch (action.type) {
        // Lấy value của username và password
        case 'POP_NOTIF':
            state = {
                ...state,
                ...action.payload,
                trigger: !state.trigger,
            };
            if (action.payload.time) {
                state.time = action.payload.time;
            } else {
                state.time = null;
            }

            if (action.payload.position) {
                state.position = action.payload.position;
            } else {
                state.position = null;
            }
            break;
    }
    return state;
};

export default notificationReducer;
