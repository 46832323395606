import React from 'react';
import ReactNotification, { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import './Notification.scss';

class Notification extends React.Component {
    Content = (type, title, icon, message) => {
        let duration = 10000;
        if (this.props.time !== null) {
            duration = this.props.time;
        }
        return (
            <div className={'notification-custom-' + type}>
                <div className="notification-custom-icon">
                    <i className={icon} />
                </div>
                <div className="notification-custom-content">
                    <p className="notification-message">{message}</p>
                </div>
                <div className="timer">
                    <div
                        className="timer-filler"
                        style={{
                            animationName: 'timer',
                            animationDuration: duration + 'ms',
                            animationTimingFunction: 'linear',
                            animationFillMode: 'forwards',
                            animationPlayState: 'running',
                        }}
                    />
                </div>
            </div>
        );
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.trigger !== this.props.trigger) {
            let title;
            let icon;
            if (this.props.type === 'success') {
                title = 'Thành công';
                icon = 'fas fa-check-circle';
            } else if (this.props.type === 'danger') {
                title = 'Lỗi';
                icon = 'fas fa-exclamation-circle';
            } else if (this.props.type === 'info') {
                title = 'Thông báo';
                icon = 'fas fa-info-circle';
            } else {
                title = 'Cảnh báo';
                icon = 'fas fa-exclamation-triangle';
            }
            store.addNotification({
                title: title,
                width: 280,
                message: this.props.message,
                type: this.props.type,
                content: this.Content(this.props.type, title, icon, this.props.message),
                insert: 'top',
                container: this.props.position !== null ? this.props.position : 'top-right',
                animationIn: ['animated', 'flipInX fast'],
                animationOut: ['animated', 'flipOutX faster'],
                dismiss: {
                    duration: this.props.time !== null ? this.props.time : 10000,
                    // onScreen: true,
                    // pauseOnHover: true,
                    showIcon: true,
                },
            });
        }
    }

    render() {
        return (
            <div>
                <ReactNotification />
            </div>
        );
    }
}

export default Notification;
