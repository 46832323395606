const loginReducer = (
    state = {
        username: {
            value: null,
            invalid: false,
            invalid_msg: null,
        },
        password: {
            value: null,
            invalid: false,
            invalid_msg: null,
        },
        logged: false,
        logging: false,
        validated: false,
    },
    action,
) => {
    switch (action.type) {
        // Lấy value của username và password
        case 'INPUT':
            state = {
                ...state,
            };
            state[action.payload.name].value = action.payload.value;
            state[action.payload.name].invalid = false;
            state[action.payload.name].invalid_msg = false;
            break;

        //  Validate và login
        case 'LOGIN':
            state = {
                ...state,
                logging: true,
            };
            break;
        case 'LOGGED':
            state = {
                ...state,
            };
            state.logged = true;
            localStorage.setItem('username', state.username.value);
            break;
        case 'LOGOUT':
            localStorage.clear();
            state = {
                ...state,
                username: {
                    value: null,
                    invalid: false,
                    invalid_msg: null,
                },
                password: {
                    value: null,
                    invalid: false,
                    invalid_msg: null,
                },
                logged: false,
                logging: false,
                validated: false,
            };
            break;
        case 'LOGIN_FAILED':
            state = {
                ...state,
                logging: false,
            };
            break;
    }
    return state;
};

export default loginReducer;
