const resultReducer = (
    state = {
        activePage: 1,
        pageSize: 10,
        totalItems: 451,
        result: [],
        sorted: [],
        loadingResult: false,
        openNoResult: false,
        popped_period_code: 0,
    },
    action,
) => {
    switch (action.type) {
        case 'CHANGE_PAGE':
            state = {
                ...state,
            };
            state.activePage = action.payload;
            state.loadingResult = true;
            break;

        case 'START_CALL_API':
            state = {
                ...state,
            };
            state.loadingResult = true;
            break;

        case 'SET_RESULT_LIST':
            let openNoResult = false;
            let popped_period_code = state.popped_period_code;
            if (state.result.length === 0) {
                openNoResult = action.payload[0].popup_status === 1;
            } else if (state.result.length > 0) {
                if (state.popped_period_code !== action.payload[0].period_code) {
                    openNoResult = action.payload[0].popup_status === 1;
                } else {
                    openNoResult = state.openNoResult;
                }
            }
            if (openNoResult) {
                popped_period_code = action.payload[0].period_code;
            }
            state = {
                ...state,
                result: action.payload,
                loadingResult: false,
                openNoResult,
                popped_period_code,
            };
            break;

        case 'SET_RESULT_SORT':
            state = {
                ...state,
                sorted: action.payload,
                loadingResult: true,
            };
            break;
        case 'SAVE_RESULT':
            state = {
                ...state,
                result: [...state.result],
            };
            state.result[action.payload.index] = {
                ...state.result[action.payload.index],
                result: action.payload.result
                    .join(' ')
                    .split(' ')
                    .sort(),
            };
            break;
        case 'CLEAN_UP_RESULT':
            state = {
                activePage: 1,
                pageSize: 10,
                totalItems: 451,
                result: [],
                sorted: [],
                loadingResult: false,
                openNoResult: false,
            };
            break;
        case 'CONFIRM_RESULT':
            state = {
                ...state,
                result: [...state.result],
            };
            state.result[0] = {
                ...state.result[0],
                confirm_status: 1,
            };
            break;
        case 'TOGGLE_NO_RESULT':
            state = {
                ...state,
                openNoResult: !state.openNoResult,
            };
    }
    return state;
};

export default resultReducer;
