export function homeLoading(loadingState) {
    return {
        type: 'LOADING',
        payload: loadingState,
    };
}

export function setTopMenu(payload) {
    return {
        type: 'SET_TOP_MENU',
        payload: payload,
    };
}
