const homeReducer = (
    state = {
        home_loading: false,
        topMenu: [],
    },
    action,
) => {
    switch (action.type) {
        case 'LOADING':
            state = {
                ...state,
            };
            state.home_loading = action.payload;
            break;
        case 'SET_TOP_MENU':
            state = {
                ...state,
                topMenu: action.payload,
            };
            break;
    }
    return state;
};

export default homeReducer;
