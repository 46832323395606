import moment from 'moment';

const now = moment().toDate();

const transactionHistoryReducer = (
    state = {
        activePage: 1,
        pageSize: 50,
        totalItems: 0,
        transactionList: [],
        sorted: [],
        loadingTransactionHistory: false,
        transHisDateRange: [now, now],
        transHisDiemBan: null,
        transHisStatus: null,
        transHisTicket: null,
        transHisTransCode: null,
        transHisPhone: null,
        transHisCustomer: null,
        transPeriodCode: null,
        transactionChartData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        transChartDate: null,
        totalOrder: 0,
        totalRevenue: 0,
        avgTicketPrice: 0,
        totalTicket: 0,
        partnerSearch: null,
        from_period: null,
        to_period: null,
    },
    action,
) => {
    switch (action.type) {
        case 'CHANGE_PAGE':
            state = {
                ...state,
            };
            state.activePage = action.payload;
            state.loadingTransactionHistory = true;
            break;

        case 'START_CALL_API':
            state = {
                ...state,
            };
            state.loadingTransactionHistory = true;
            break;

        case 'SET_TRANSACTION_LIST':
            state = {
                ...state,
                totalOrder: action.payload.total_order,
                totalRevenue: action.payload.total_revenue,
                avgTicketPrice: action.payload.avg_order_revenue,
                totalTicket: action.payload.total_ticket,
            };
            try {
                state.totalItems = action.payload.page_info.total_record;
            } catch (e) {
                state.totalItems = 0;
            }
            state.transactionList = action.payload.items;
            state.loadingTransactionHistory = false;
            break;

        case 'SET_TRANSACTION_PERIOD_CODE':
            state = {
                ...state,
                transPeriodCode: action.payload,
            };
            break;

        case 'SET_TRANSACTION_DATE':
            state = {
                ...state,
                transHisDateRange: action.payload,
            };
            break;

        case 'SET_TRANSACTION_DIEM_BAN':
            state = {
                ...state,
            };
            state.transHisDiemBan = action.payload;
            break;
        case 'SET_TRANSACTION_STATUS':
            state = {
                ...state,
            };
            state.transHisStatus = action.payload;
            break;

        case 'SET_TRANSACTION_TICKET':
            state = {
                ...state,
            };
            state.transHisTicket = action.payload;
            break;

        case 'SET_TRANSACTION_TICKET_CODE':
            state = {
                ...state,
            };
            state.transHisTransCode = action.payload;
            break;

        case 'SET_TRANSACTION_PHONE':
            state = {
                ...state,
            };
            state.transHisPhone = action.payload;
            break;

        case 'SET_TRANSACTION_CUSTOMER':
            state = {
                ...state,
            };
            state.transHisCustomer = action.payload;
            break;

        case 'SET_TRANSACTION_CHART_DATE':
            state = {
                ...state,
                transChartDate: action.payload,
            };
            break;

        case 'SET_TRANSACTION_CHART_DATA':
            state = {
                ...state,
                transactionChartData: action.payload,
            };
            break;

        case 'SET_PARTNER':
            state = {
                ...state,
                partnerSearch: action.payload,
            };
            break;

        case 'SET_TRANSACTION_SORT':
            state = {
                ...state,
                sorted: action.payload,
                // activePage: 1,
                // loadingTransactionHistory: true
            };
            break;

        case 'SET_TRANSACTION_PAGE_SIZE':
            state = {
                ...state,
                pageSize: action.payload,
            };
            break;

        case 'SET_TRANSACTION_FROM_PERIOD':
            state = {
                ...state,
                from_period: action.payload,
            };
            break;

        case 'SET_TRANSACTION_TO_PERIOD':
            state = {
                ...state,
                to_period: action.payload,
            };
            break;

        case 'CLEAN_TRANSACTION_FILTERS':
            state = {
                ...state,
                activePage: 1,
                pageSize: 50,
                totalItems: 0,
                transactionList: [],
                sorted: [],
                loadingTransactionHistory: false,
                transHisDateRange: [now, now],
                transHisDiemBan: null,
                transHisStatus: null,
                transHisTicket: null,
                transHisTransCode: null,
                transHisTicketCode: null,
                transHisPhone: null,
                transHisCustomer: null,
                transactionChartData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                transChartDate: null,
                transPeriodCode: null,
                partnerSearch: null,
                from_period: null,
                to_period: null,
            };
            break;
    }
    return state;
};

export default transactionHistoryReducer;
