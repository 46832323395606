import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

// Reducers
import HomeReducer from './Reducers/homeReducer';
import loginReducer from './Reducers/loginReducer';
import transactionHistoryReducer from './Reducers/transactionHistoryReducer';
import notificationReducer from './Reducers/notificationReducer';
import resultReducer from './Reducers/resultReducer';
import rulesReducer from './Reducers/RulesReducer';
import bigWin from './Reducers/bigWin';

const logger = createLogger({});

export default createStore(
    combineReducers({
        HomeReducer,
        loginReducer,
        transactionHistoryReducer,
        notificationReducer,
        resultReducer,
        rulesReducer,
        bigWin,
    }),
    {},
    applyMiddleware(logger, thunk),
);
