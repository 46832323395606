import React, { Component } from 'react';
import {
    BrowserRouter,
    Route,
    // Switch
} from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { connect } from 'react-redux';
import { homeLoading } from './Actions/homeActions';
import { Loading } from './Components/Loading/Loading';
import axios from 'axios';
import Notification from './Components/Notification/Notification';

axios.defaults.timeout = 120000;

const Login = React.lazy(() => import('./views/Pages/Login/Login'));

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

const mapStateToProps = (state) => {
    return {
        HomeReducer: state.HomeReducer,
        notificationReducer: state.notificationReducer,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        homeLoading: (loadingState) => {
            dispatch(homeLoading(loadingState));
        },
    };
};

class App extends Component {
    componentDidMount() {
        setTimeout(() => {
            this.props.homeLoading(true);
        }, 1000);
    }

    render() {
        if (this.props.HomeReducer.home_loading === false) {
            return <Loading />;
        } else {
            return (
                <div>
                    <Notification
                        message={this.props.notificationReducer.message}
                        type={this.props.notificationReducer.notifType}
                        trigger={this.props.notificationReducer.trigger}
                        time={this.props.notificationReducer.time}
                        position={this.props.notificationReducer.position}
                    />
                    <BrowserRouter>
                        <React.Suspense fallback={<Loading />}>
                            <Route exact path="/login" name="Login" render={(props) => <Login {...props} />} />
                            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
                        </React.Suspense>
                    </BrowserRouter>
                </div>
            );
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);
